@import "@/assets/scss/style.scss";

.ant-row {
    padding: 5px 0;

    .sidebar-creation-source {
        text-align: center;
        color: $grey-8;
        padding-left: 5px;
        font-size: 14px;
    }
}
.sidebar-creation-source-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
