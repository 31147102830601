@import "@/assets/scss/style.scss";

.ant-row {
    padding: 5px 0;
    .ant-col {
        &:first-child {
            color: $grey-7;
            font-size: 12px;
        }
        &:last-child {
            color: $grey-9;
            font-size: 12px;
            text-align: right;
        }
    }
}

.general-info-info-row {
    // @include flex-center;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
