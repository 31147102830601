@import "@/assets/scss/style.scss";

.ant-row {
    padding: 5px 0;

    .side-bar__customer-notes {
        color: $grey-8;
        font-size: 14px;
        white-space: pre-line;
    }
}
