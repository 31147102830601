@import "@/assets/scss/style.scss";

.wrapper-edit-client {
    padding: 24px 64px;
    height: 100%;

    @media (max-width: 768px) {
        padding: 24px 16px;
    }

    .ant-tabs-nav .ant-tabs-tab {
        padding: 12px 0;
    }

    .ant-tabs {
        height: 100%;
    }

    .ant-tabs-bar {
        border: none;
    }

    .loading-icon-wrapper {
        display: flex;
        justify-content: center;
        width: auto;

        i {
            font-size: 36px;
            display: flex;
            align-self: center;
            margin-top: 351px;
        }
    }

    &__loading {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.6);
        z-index: 5;
    }

    .main-title {
        font-weight: bold;
    }

    .header-section {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 15px;
        margin-bottom: 15px;

        .title {
            font-weight: bold;
            padding-right: 10px;
            color: rgba(0, 0, 0, 0.75);
        }

        .border {
            flex-grow: 2;
            height: 1px;
        }
    }

    .form-section {
        margin-bottom: 40px;
    }

    .label {
        span {
            color: $ant-red;
        }

        color: $grey-8;
    }
}

.border {
    flex-grow: 2;
    border-bottom: 1px dashed $grey-4;
    height: 1px;
}

.background-color {
    background-color: #ffabab;
}
