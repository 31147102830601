@import "@/assets/scss/style.scss";

.client-view-sidebar-title {
    color: $grey-8;
    font-weight: bold;
}

.sidebar-card-title {
    font-weight: bold;
    font-size: 14px;
    margin: 0;
}

.client-view-sidebar-wrapper {
    margin-bottom: 15px;
}

.client-sidebar-icon {
    color: #000;
}

.client-sidebar--bad-payer {
    .ant-layout-content {
        background-color: #ffacac;
    }

    .ant-layout-sider-children {
        background-color: #ffacac;
    }
}
