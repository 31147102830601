@import "@/assets/scss/style.scss";

.ant-layout {
    min-height: 100%;
    background-color: $white-two;
}

.ant-layout-header {
    background-color: $white-two;
    height: 100%;
    padding: 0 64px;
    border-bottom: 1px solid $grey-4;
}
