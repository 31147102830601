@import "@/assets/scss/style.scss";

.client-top-bar {
    display: flex;
    width: auto;
    align-items: center;
    justify-content: space-between;
    min-height: 5rem;
    height: 100%;
    // flex-wrap: wrap;

    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        padding: 0.5rem 0;

        .right-side-actions {
            margin-top: 1rem;
        }
    }

    .left-side-actions {
        display: flex;
        align-items: center;
        justify-content: center;
        .product-info {
            padding: 0 0.75rem;
            line-height: 1.33;

            @media (max-width: 600px) {
                display: flex;
                flex-direction: column;
            }

            .product-type {
                font-size: 1.125rem;
                font-weight: 400;
                color: $grey-7;

                @media (max-width: 600px) {
                    height: 1.5rem;
                }
            }
            .product-name {
                font-size: 1.125rem;
                font-weight: bold;
                color: $grey-9;
            }
        }
    }
}

.right-side-actions {
    display: flex;
    align-items: center;
    justify-content: center;
}
.misc {
    padding: 0 8px;
}
.icon-color {
    color: $grey-9;
}
