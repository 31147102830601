@import "@/assets/scss/style.scss";

.ant-row {
    padding: 5px 0;

    .tag {
        background-color: $grey-three;
        text-align: center;
        border-radius: 2px;
        color: $grey-9;
        width: fit-content;
        padding: 3px 8px;
        margin: 0 5px 5px 0;
        font-size: 12px;
    }
}

.tag-wrapper {
    display: flex;
    flex-wrap: wrap;
}
