@import "@/assets/scss/style.scss";

.ant-row {
    padding: 5px 0;
}

.wrapper {
    padding: 2px;

    &::v-deep {
        .ant-upload.ant-upload-drag {
            border: none;
            background: transparent;

            .ant-upload-text {
                @extend .u-b1;
                color: $grey-7;
            }
        }
    }
}
